import React from 'react'

import './figure.module.css'

export default (props) => {
  let PDFpath = props.asset._ref
  PDFpath = PDFpath.replace('file-', '')
  PDFpath = PDFpath.replace('-pdf', '')
  PDFpath = `https://cdn.sanity.io/files/4v4t20cr/production/${PDFpath}.pdf`
  return (
    <>
      <object data={PDFpath} type='application/pdf' width='100%' height='960px' internalinstanceid='11' />
      <a href={PDFpath}>Download</a>
    </>
  )
}