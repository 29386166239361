import React from 'react'
import Figure from './Figure'
import PDFfile from './PDFfile'
import Youtube from './Youtube'
import Slideshow from './Slideshow'
import Gallery from './Gallery'
import Iframe from './Iframe'
import {Link} from 'gatsby'
import FileDownload from './FileDownload'

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    youtube (props) {
      return <Youtube {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    },
    imageGallery (props) {
      return <Gallery {...props.node} />
    },
    pdfFile (props) {
      return <PDFfile {...props.node} />
    },
    iframe (props) {
      return <Iframe {...props.node} />
    }
  },
  marks: {
    fileDownload (props) {
      // console.log({props})
      switch (props.mark._type) {
        case 'fileDownload':
          if (props.mark.file) { return <FileDownload {...props} /> } else { return null }
      }
    },
    link: ({mark, children}) => {
      const {blank, href} = mark
      if (!href) {
        return null
      }
      return blank === true
        ? <>
          {href.includes('https') || href.includes('http') ? (
            <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>
          ) : (
            <a href={href}>{children}</a>
          )}
        </>
        : <>
          {href.includes('https') || href.includes('http') || href.includes('tel') || href.includes('mailto') ? (
            <a href={href}>{children}</a>
          ) : (
            <Link to={href}>{children}</Link>
          )}
        </>
    }
  }
}

export default serializers
