import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import styles from './layout.module.css'
const FooterSocial = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
            query sanityFooterSocial {
              sanitySiteSettings {
                socialLinks {
                  _key
                  title
                  siteLink
                }
              }
            }
          `}
        render={data => (
          <>
            <ul>
              {data.sanitySiteSettings.socialLinks.map(social => (
                <li key={social._key}>
                  <a href={social.siteLink} target='_blank' rel='noopener noreferrer' title={social.title} className={styles.social}>
                    {social.title}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      />

    </>
  )
}

export default FooterSocial
