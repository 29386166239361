import React from 'react'
import {graphql, StaticQuery, Link} from 'gatsby'
import Img from 'gatsby-image'

const FooterSponsors = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
            query sanityFooterSponsors {
              sanitySiteSettings {
                partners {
                  _key
                  title
                  link
                  image {
                    asset {
                      fixed(width: 80) {
                      ...GatsbySanityImageFixed
                    }
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <h4>Site Sponsors</h4>
            <ul>
              {data.sanitySiteSettings.partners.map(sponsor => (
                <li key={sponsor._key}>
                  <a href={sponsor.link} target='_blank' rel='noopener noreferrer'>
                    <Img fixed={sponsor.image.asset.fixed} alt={sponsor.title} />
                  </a>
                </li>
              ))}
            </ul>
            <span><Link to='/about'>view all sponsors</Link></span>
          </>
        )}
      />

    </>
  )
}

export default FooterSponsors
