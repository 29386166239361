import React from 'react'
// import {Link, useStaticQuery, graphql} from 'gatsby'
import Image from 'gatsby-image'
import {Location} from '@reach/router'

import HeroPlaceholder from '../components/heroImagePlaceholder'

import Header from './header'
import FooterNav from './footerNav'
import FooterSponsors from './footerSponsors'
import FooterSocial from './footerSocial'
import FooterContact from './footerContact'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  mainImage,
  title,
  homePage
}) => (

  (
    <>
      {/* <div className={location.pathname === '/' ? styles.contentHome : styles.content}> */}
      <Location children={children}>
        {({location}) => {
          // console.log({location})
          // console.log({mainImage})
          return (
            <div className={location.pathname.replace('/', '') === '' ? 'contentHome wrapper' : location.pathname.replace('/', '' + ' subPage ')}>

              <div className={homePage ? styles.headerWrapperHome : styles.headerWrapper}>
                <Header location={location.pathname} siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />

                {location.pathname.replace('/', '') === '' ? (
                  <div className={styles.homeOverlay}>
                    <svg viewBox='0 0 1500 900' xmlns='http://www.w3.org/2000/svg'><g fill='none'><path d='M1505.958 763.745C768.468 540.006 382.245 285.758 347.29 1H10v790l1504.693-4.271-8.735-22.984z' fill='#124734' /><path d='M1502.958 767.745C738.8 540.006 339.245 283.758 304.29-1H-33l-8.735 905.271L1502.958 900V767.745z' fill='#FFFFFF' /></g></svg>
                  </div>
                ) : (
                  <div className={styles.subPageOverlay}>
                    <svg viewBox='0 0 1440 447' xmlns='http://www.w3.org/2000/svg'><g fillRule='nonzero' fill='none'><path d='M1440 345.031C706.482 400.435 322.245 285.758 287.29 1H-50v469h1490V345.031z' fill='#124734' /><path d='M1442.958 365.18C678.8 405.818 279.245 283.758 244.29-1H-93l-8.735 475.271L1442.958 470V365.18z' fill='#FFFFFF' /></g></svg>
                  </div>
                )}

                {mainImage && mainImage.asset ? (
                  <>{homePage ? (<div className={styles.mainImage}>
                    <Image fluid={mainImage.asset.fluid} alt={title} style={{height: '100%'}} />
                  </div>) : (<div className={styles.mainImage}>
                    <Image fluid={mainImage.asset.fluid} alt={title} />
                  </div>)}</>

                ) : (<div className={styles.mainImage}><HeroPlaceholder /></div>)}
              </div>

              <div>
                {[children]}
              </div>
            </div>
          )
        }}
      </Location>

      <footer className={styles.footer}>
        <div className={styles.topFooter}>
          <div className={styles.footerWrapper}>
            {/* site sponsors */}
            <div className={styles.siteSponsors}>
              <FooterSponsors />
            </div>

            {/* social */}
            <div className={styles.siteSocial}>
              <FooterSocial />
            </div>

            {/* contact info */}
            <div className={styles.siteContact}>
              <FooterContact />
            </div>

          </div>
        </div>

        <div className={styles.bottomFooter}>
          <div className={styles.footerWrapper}>
            {/* Bottom Footer  */}
            <div className={styles.siteInfo}>
              {/* copyright */}
            &copy; {new Date().getFullYear()} {siteTitle} All rights reserved.
              {/* bottom footer navigation */}
              <FooterNav />
            </div>
          </div>
        </div>

      </footer>

    </>
  )
)

export default Layout
