import {graphql, StaticQuery} from 'gatsby'
import React from 'react'
import Navigation from './Navigation'

const FooterNav = () => {
  return (
    <div>
      <StaticQuery
        query={graphql`
            query sanityFooterNav {
              sanityNavigation(_id: { eq: "b126cf74-6e66-4723-b0cf-5b7b2f09cebc" }) {
                links {
                  _key
                  title
                  siteLink
                }
              }
            }
          `}
        render={data => (
          <nav>
            <Navigation nav={data.sanityNavigation} />
          </nav>
        )}
      />

    </div>
  )
}

export default FooterNav
