import React from 'react'
import {graphql, StaticQuery, Link} from 'gatsby'
import styles from './layout.module.css'
const FooterContact = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
            query sanityFooterContact {
              sanitySiteSettings {
                siteAddress {
                  companyName
                  street
                  city
                  state
                  zip
                  phone
                  email
                }
              }
            }
          `}

        render={data => (
          <>
            <h4>Stay Connected</h4>
            <address>
              <div className="vcard">

                <div className="org sr-only">{data.sanitySiteSettings.siteAddress.companyName}</div>

                  <div className="adr">
                    <div className="street-address">{data.sanitySiteSettings.siteAddress.street}</div>
                    <span className="locality">{data.sanitySiteSettings.siteAddress.city}</span> <span className="region">{data.sanitySiteSettings.siteAddress.state}</span>{ ' '}
                    <span className="postal-code">{data.sanitySiteSettings.siteAddress.zip}</span>
                    <span className="country-name sr-only">United States</span>
                  </div>

                    <div className="tel">
                      <a href={`tel:${data.sanitySiteSettings.siteAddress.phone}`} className="">{data.sanitySiteSettings.siteAddress.phone}</a>
                    </div>

                    <div className="email">
                      <Link to={data.sanitySiteSettings.siteAddress.email} className="">Email Us</Link>
                    </div>

              </div>
            </address>
          </>
        )}
      />

    </>
  )
}

export default FooterContact
