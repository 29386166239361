import {Link, graphql, StaticQuery} from 'gatsby'
import React from 'react'
import Logo from '../components/logo'
import Navigation from './Navigation'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({location, onHideNav, onShowNav, showNav, siteTitle}) => {
  // console.log({Logo})
  return (
    <div className={location === '/' ? styles.rootHome : styles.root}>
      <div className={styles.wrapper} style={{alignItems: `top`}}>
        <div className={styles.branding}>
          <Link to='/'><Logo /></Link>
        </div>

        <button className={cn(styles.toggleNavButton, showNav && styles.showNav)} onClick={showNav ? onHideNav : onShowNav} aria-label='Mobile Navigation Trigger'>
          <Icon symbol='hamburger' />
        </button>

        <StaticQuery
          query={graphql`
            query sanityNav {
              sanityNavigation(_id: { eq: "a9ce55ff-a687-40d4-8cb2-b7d8fc4d6c64" }) {
                links {
                  _key
                  title
                  siteLink
                }
              }
            }
          `}
          render={data => (
            <nav className={cn(styles.nav, showNav && styles.showNav)}>
              <Navigation nav={data.sanityNavigation} main />
            </nav>
          )}
        />

      </div>
    </div>
  )
}

export default Header
